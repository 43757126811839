import React, { useState, useEffect } from "react";
import { HeadFC, PageProps } from "gatsby";
import { IProduct } from "../dtos/IProduct.dto";
import { HeadSEO } from "../components/head";
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter";

import { productCategoriesFilter } from "../mocks/productCategoriesFilter";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { ProductCard } from "../components/product_card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import LatestPosts from "../components/lastposts";
import { useDisclosure } from "@chakra-ui/react";
import RelatedReceipts from "../components/recommendedreceipts";

interface LinhaPageContext {
    line: {
        id: number;
        name: string;
        category_id: string;
        image: string;
        color: string;
        claims: string[];
        description: string;
    };
    products: IProduct[];
}

// Função para normalizar strings (remover acentos, deixar lowercase)
const normalizeText = (str: string): string =>
    str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

const LinhaTemplate: React.FC<PageProps<{}, LinhaPageContext>> = ({ pageContext }) => {
    const { line, products } = pageContext;
    const [selectedCategory, setSelectedCategory] = useState<string | null>('Todos');
    const [categoriesWithProducts, setCategoriesWithProducts] = useState<any[]>([]);

    const filterProducts = (product: IProduct): boolean => {
        if (selectedCategory && selectedCategory !== 'Todos') {
            const productTitle = normalizeText(product.title.rendered);

            const selectedCategoryValues = productCategoriesFilter.find(
                (category) => category.name === selectedCategory
            )?.value.split(",").map(v => normalizeText(v.trim()));

            if (selectedCategoryValues) {
                return selectedCategoryValues.some(category =>
                    productTitle.includes(category)
                );
            }
        }
        return true;
    };

    const filterCategoriesWithProducts = () => {
        const filteredCategories = productCategoriesFilter.filter((category) => {
            const categoryValues = category.value.split(",").map(v => normalizeText(v.trim()));

            return products.some((product) =>
                categoryValues.some(cat =>
                    normalizeText(product.title.rendered).includes(cat)
                )
            );
        });

        setCategoriesWithProducts(filteredCategories);
    };

    const categories =
        categoriesWithProducts.length === 0
            ? ["Todos"]
            : categoriesWithProducts.length === 1
                ? [categoriesWithProducts[0].name]
                : ["Todos", ...categoriesWithProducts.map((category) => category.name)];

    useEffect(() => {
        if (categoriesWithProducts.length === 1) {
            setSelectedCategory(categoriesWithProducts[0].name);
        } else {
            setSelectedCategory("Todos");
        }
    }, [categoriesWithProducts]);

    useEffect(() => {
        filterCategoriesWithProducts();
    }, [products]);

    const sortedProducts = products.sort((a, b) =>
        (b.legenda_do_vdeo === "novo" ? 1 : 0) - (a.legenda_do_vdeo === "novo" ? 1 : 0)
    );

    const filteredProducts = sortedProducts.filter(filterProducts);
    const destaqueProducts = products.filter(product => product.legenda_do_vdeo === "novo");

    return (
        <div className="bg-white">
            <h1 className="text-center py-4 h2 text-title-litle-line">{line.name}</h1>

            {destaqueProducts.length > 0 && (
                <div className="container">
                    <div className="h2 text-title-litle pb-4 mb-4" style={{ borderBottom: "1px solid #cecece" }}>Destaques</div>
                    <Swiper
                        className="destaques-linha"
                        modules={[Navigation]}
                        spaceBetween={20}
                        slidesPerView={1.3}
                        navigation
                        breakpoints={{
                            640: { slidesPerView: 2 },
                            768: { slidesPerView: 3 },
                            1024: { slidesPerView: 4 },
                        }}
                    >
                        {destaqueProducts.map((product) => (
                            <SwiperSlide key={product.id}>
                                <div className="d-flex justify-content-center">
                                    <ProductCard
                                        activeRibbon={product.legenda_do_vdeo === "novo"}
                                        ribbonText={product.legenda_do_vdeo === "novo" ? "Novidade" : ""}
                                        product={product}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}

            <div className="col-12 mt-5 bg-produtos">
                <div className="container my-4 text-center py-4" style={{ borderBottom: "1px solid #cecece" }}>
                    <div className="btn-group gap-2">
                        {categories.map((category) => (
                            <button
                                key={category}
                                className={`btn btn-filtro ${selectedCategory === category ? "btn-warning" : "btn-outline-secondary"}`}
                                onClick={() => setSelectedCategory(category)}
                            >
                                {category}
                            </button>
                        ))}
                    </div>
                </div>

                <div className="container py-5">
                    <div className="row">
                        {filteredProducts.map((product) => (
                            <div key={product.id} className="col-6 px-1 px-lg-4 col-lg-4 mb-4 product-card-lines">
                                <ProductCard
                                    lineCard
                                    activeRibbon={product.legenda_do_vdeo === "novo"}
                                    ribbonText={product.legenda_do_vdeo === "novo" ? "Novidade" : ""}
                                    product={product}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="container">
                <RelatedReceipts keyword={line.name} />
            </div>

            <div className="container">
                <LatestPosts keyword={line.name} />
            </div>

            <div className="container">
                <div className="card-reclamacao py-5">
                    <div className="row">
                        <div className="col-xl-4 col-10 text-center mx-auto">
                            <span className="h2 text-center mb-1">Ainda restou alguma dúvida?</span>
                            <p className="lead mt-0">
                                Clique em um dos botões abaixo e nos conte melhor sobre o seu problema ou dúvida.
                            </p>
                            <div className="d-flex flex-column align-items-center">
                                <a href="/faq" className="btn btn-duvida my-1">Tenho uma Dúvida</a>
                                <a href="/faq" className="btn btn-reclamacao my-1">Fazer reclamação</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LinhaTemplate;

export const Head: HeadFC = ({ pageContext }) => {
    const { line } = pageContext as LinhaPageContext;
    const title = `Conheça a linha ${line.name} | Produtos Saudáveis - Verde Campo`;
    const description = line.description;
    return HeadSEO(capitalizeFirstLetter(title), description, "");
};
