export const productCategoriesFilter = [
  {
    id: 1,
    name: "Iogurtes",
    value: `
      iogurte lacfree, 
      iogurte natural whey, 
      iogurte desnatado 2 ingredientes lacfree, 
      iogurte natural desnatado 2 ingredientes lacfree, 
      iogurte integral 2 ingredientes, 
      iogurte natural integral 2 ingredientes,
      iogurte probiotico,
      iogurte kids
    `
  },
  {
    id: 18,
    name: "Kefir",
    value: "kefir"
  },
  {
    id: 4,
    name: "Shakes",
    value: "shake"
  },
  {
    id: 2,
    name: "Queijos",
    value: "queijo minas, queijo mussarela, queijo frescal"
  },
  {
    id: 14,
    name: "Pastas",
    value: "requeijao, cottage"
  },
  {
    id: 5,
    name: "Cremes",
    value: "creme"
  },
  {
    id: 10,
    name: "Sobremesas",
    value: "sobremesa"
  }
];
